import React, { createContext, useContext, useState, useEffect } from 'react';

const SubscriptionContext = createContext();

export function SubscriptionProvider({ children }) {
  const [pendingSubscriptionRedirect, setPendingSubscriptionRedirect] = useState(false);

  useEffect(() => {
    // Only access to sessionStorage in the client
    const fromSubscription = sessionStorage.getItem('fromSubscription') === 'true';
    setPendingSubscriptionRedirect(fromSubscription);
  }, []);

  useEffect(() => {
    if (pendingSubscriptionRedirect) {
      sessionStorage.setItem('fromSubscription', 'true');
    } else {
      sessionStorage.removeItem('fromSubscription');
    }
  }, [pendingSubscriptionRedirect]);

  // Clean the storage when the component is unmounted
  useEffect(() => {
    return () => {
      sessionStorage.removeItem('fromSubscription');
    };
  }, []);

  const value = {
    pendingSubscriptionRedirect,
    setPendingSubscriptionRedirect
  };

  return (
    <SubscriptionContext.Provider value={value}>
      {children}
    </SubscriptionContext.Provider>
  );
}

export function useSubscription() {
  const context = useContext(SubscriptionContext);
  if (context === undefined) {
    throw new Error('useSubscription must be used within a SubscriptionProvider');
  }
  return context;
}

export default SubscriptionProvider; 