import { apiBaseUrl } from '../api';

const createCheckoutSession = async ({ userId, userEmail }) => {
  try {
    const baseUrl = apiBaseUrl();
    const response = await fetch(`${baseUrl}/api/stripe-checkout`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId, userEmail }),
    });

    if (!response.ok) {
      throw new Error('Error creating checkout session');
    }

    return response.json();
  } catch (error) {
    throw error;
  }
};

const createPortalSession = async ({ customerId }) => {
  try {
    const baseUrl = apiBaseUrl();
    const response = await fetch(`${baseUrl}/api/stripe-checkout/portal`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ customerId }),
    });

    if (!response.ok) {
      throw new Error('Error creating portal session');
    }

    return response.json();
  } catch (error) {
    throw error;
  }
};

export const stripeOperations = {
  createCheckoutSession,
  createPortalSession,
}; 