import React from 'react';
import css from './LoadingSpinner.module.css';
import michaelCool from '../../assets/michael-cool.png';

const LoadingSpinner = () => {
  return (
    <div className={css.loadingContainer}>
      <img 
        src={michaelCool} 
        alt="Loading..." 
        className={css.loadingSpinner}
      />
      <p className={css.loadingText}>Loading...</p>
    </div>
  );
};

export default LoadingSpinner; 