import React, { createContext, useState, useContext, useEffect, useRef } from 'react';
import { createInstance } from 'sharetribe-flex-sdk';
import LoadingSpinner from '../components/LoadingSpinner/LoadingSpinner';

const LandingPageContext = createContext();

// Memory cache for immediate access
let memoryCache = null;

const getStoredData = () => {
  // First check the memory cache
  if (memoryCache) {
    return memoryCache;
  }

  // Check if we're on the client
  if (typeof window !== 'undefined') {
    try {
      const stored = sessionStorage.getItem('landingPageData');
      if (stored) {
        const { data, timestamp } = JSON.parse(stored);
        if (Date.now() - timestamp < 5 * 60 * 1000) {
          memoryCache = data; // Save to memory for future access
          return data;
        }
      }
    } catch (e) {
      console.error('Error reading from sessionStorage:', e);
    }
  }
  return null;
};

export const LandingPageProvider = ({ children }) => {
  const [data, setData] = useState(() => {
    // Try to get data from the cache only on the client
    if (typeof window !== 'undefined') {
      return getStoredData();
    }
    return null;
  });

  const [state, setState] = useState({
    listings: data?.listings || [],
    users: data?.users || [],
    reviews: data?.reviews || [],
    isLoading: false, // Changed to false if there's initial data
    error: null,
    listingsResponse: data?.listingsResponse || null,
    usersResponse: data?.usersResponse || null,
  });

  const isMounted = useRef(true);
  const isInitialMount = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    // Si tenemos datos iniciales o no es el montaje inicial, no hacemos fetch
    if (data || !isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    const fetchData = async () => {
      if (!isMounted.current) return;

      setState(prev => ({ ...prev, isLoading: true }));
      
      try {
        const sdk = createInstance({
          clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID
        });

        // 1. Fetch ALL listings first (we'll use this for reviews and users)
        const allListingsResponse = await sdk.listings.query({
          pub_availability: 'available',
          include: ['images', 'author', 'author.profileImage'],
          'fields.image': [
            'variants.default',
            'variants.landscape-crop',
            'variants.landscape-crop2x'
          ],
          per_page: 100,
          sort: '-createdAt'
        });

        // 2. Filter featured listings for the hero section
        const featuredListings = {
          ...allListingsResponse.data,
          data: allListingsResponse.data.data.filter(
            listing => listing.attributes.publicData?.featuredStudio === 'yes'
          ).slice(0, 4)
        };

        // 3. Extract unique authors (studio owners)
        const uniqueAuthors = [...new Map(
          allListingsResponse.data.included
            ?.filter(included => included.type === 'user')
            .filter(user => user.relationships?.profileImage?.data)
            .map(user => [user.id.uuid, user])
        ).values()];

        // Update state with listings and users
        setState(prev => ({
          ...prev,
          listings: featuredListings,
          users: uniqueAuthors,
          listingsResponse: allListingsResponse.data,
        }));

        // 4. Fetch reviews for ALL listings
        const listingIds = allListingsResponse.data.data.map(listing => listing.id.uuid);
        const allReviews = [];

        // Fetch reviews in parallel instead of sequentially
        const reviewPromises = listingIds.map(listingId => 
          sdk.reviews.query({
            listingId: listingId,
            state: 'public',
            include: ['author', 'listing']
          }).catch(error => {
            console.log(`Error fetching reviews for listing ${listingId}:`, error);
            return null;
          })
        );

        const reviewsResponses = await Promise.all(reviewPromises);

        reviewsResponses.forEach((response, index) => {
          if (!response) return;

          const listingId = listingIds[index];
          const reviews = response.data.data
            .filter(review => 
              review.attributes.rating >= 4 && 
              review.attributes.content &&
              review.attributes.state === 'public'
            )
            .map(review => {
              const author = response.data.included?.find(
                inc => inc.type === 'user' && inc.id.uuid === review.relationships.author.data.id.uuid
              );
              const listing = allListingsResponse.data.data.find(l => l.id.uuid === listingId);

              return {
                id: review.id.uuid,
                text: review.attributes.content,
                rating: review.attributes.rating,
                author: author?.attributes?.profile?.displayName || 'Anonymous',
                listing: listing?.attributes?.title || 'Studio Space'
              };
            });

          allReviews.push(...reviews);
        });

        // Sort by rating and take the top 5
        const topReviews = allReviews
          .sort((a, b) => b.rating - a.rating)
          .slice(0, 5);

        const newData = {
          listings: featuredListings,
          users: uniqueAuthors,
          reviews: topReviews,
          listingsResponse: allListingsResponse.data,
        };

        // Update both caches
        memoryCache = newData;
        try {
          sessionStorage.setItem('landingPageData', JSON.stringify({
            data: newData,
            timestamp: Date.now()
          }));
        } catch (e) {
          console.error('Error saving to sessionStorage:', e);
        }

        if (isMounted.current) {
          setState({
            ...newData,
            isLoading: false,
            error: null,
          });
        }

      } catch (error) {
        console.error('Error fetching landing page data:', error);
        if (isMounted.current) {
          setState(prev => ({ ...prev, error, isLoading: false }));
        }
      }
    };

    isInitialMount.current = false;
    fetchData();
  }, []);

  const refreshData = () => {
    memoryCache = null;
    sessionStorage.removeItem('landingPageData');
    setState(prev => ({ ...prev, isLoading: true }));
  };

  // Only show loading if we're actually loading and there's no data
  if (state.isLoading && !state.listings.length) {
    return <LoadingSpinner />;
  }

  return (
    <LandingPageContext.Provider value={{ ...state, refreshData }}>
      {children}
    </LandingPageContext.Provider>
  );
};

export const useLandingPage = () => {
  const context = useContext(LandingPageContext);
  if (context === undefined) {
    throw new Error('useLandingPage must be used within a LandingPageProvider');
  }
  return context;
}; 